export const button_ja = {
  CREATE: '作成',
  EDIT: '編集',
  UPDATE: '更新',
  CREATE_STORE: 'ストアを作成',
  CREATE_WEBSITE: 'ウエブサイト作成',
  CREATE_POST_LIST: '投稿リスト作成',
  CREATE_UGC_SET: 'UGCセット作成',
  APPROVE_ALL: '一括承認',
  PUBLISH: '公開する',
  EXPORT_EXCEL: 'エクセル出力',
  GENERATE_PDF: 'PDF作成',
  DOWNLOAD_PDF: 'PDFダウンロード',
  REFETCH_DATA: 'データを再取得',
  CONFIRM: '確定',
  CANCEL: 'キャンセル',
  GO_TO_NEXT: '次へ進む',
  GO_TO_PREVIEW: 'プレビューへ進む',
  UPDATE_UGC_SET: '更新する',
}

export const button_en = {
  CREATE: 'Create',
  EDIT: 'Edit',
  UPDATE: 'Update',
  CREATE_STORE: 'Create Store',
  CREATE_WEBSITE: 'Create Website',
  CREATE_POST_LIST: 'Create Post List',
  CREATE_UGC_SET: 'Create UGC Set',
  APPROVE_ALL: 'Approve All',
  PUBLISH: 'Publish',
  EXPORT_EXCEL: 'Export Excel',
  GENERATE_PDF: 'Generate PDF',
  DOWNLOAD_PDF: 'Download PDF',
  REFETCH_DATA: 'Refetch Data',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  GO_TO_NEXT: 'Go to next',
  GO_TO_PREVIEW: 'Go to preview',
  UPDATE_UGC_SET: 'Update UGC Set',
}
